// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// TRANSITION EFFECT
jQuery('#list-wrapper li a[href^="#"]').bind('click',function (e) {
    e.preventDefault();
    var $target = $($(e.currentTarget).attr('href')),
    offset = $target.data('scroll-offset') || 0;
    console.log ($(e.currentTarget).attr('href'));
    console.log ($target.offset().top, $('nav.cart-list').height());
    jQuery('html, body').stop().animate( {
        'scrollTop': $target.offset().top - $('nav.cart-list').height()
    }, 500, 'swing', function () {
        window.location.hash = target;
    } );

} );

// Responsive menu
jQuery('#toggle').click(function() {
  jQuery(this).toggleClass('active');
  jQuery('.primary-navigation-wrapper').toggleClass('open');
  jQuery('body').toggleClass("overflow");
});

// BTN SUB nav
jQuery('.navigation-btn').click(function(e) {
  e.preventDefault();
  jQuery('.aside-wrapper').toggleClass('show');
  //jQuery('.aside-wrapper').before('<div class="box-aside-overlay"></div>');
  //jQuery('.box-aside-overlay').click(closeAccountAside);
  //jQuery('body').css('overflow', 'hidden');
});

function closeAccountAside(e) {
  e.preventDefault();
  jQuery('.aside-wrapper').toggleClass('show');
  //jQuery('.box-aside-overlay').remove();
  //jQuery('body').css('overflow', 'auto');
}

// BTN SUB nav account
jQuery('#myAccount-link').click(function(e) {
  e.preventDefault();
  jQuery('.woocommerce-MyAccount-navigation ul').toggleClass('show');
  //jQuery('.aside-wrapper').before('<div class="box-aside-overlay"></div>');
  //jQuery('.box-aside-overlay').click(closeAccountAside);
  //jQuery('body').css('overflow', 'hidden');
});

function closeAccountAside(e) {
  e.preventDefault();
  jQuery('.woocommerce-MyAccount-navigation ul').toggleClass('show');
  //jQuery('.box-aside-overlay').remove();
  //jQuery('body').css('overflow', 'auto');
}

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

  $('.js-replace-img').each(function() {

    var src = $(this).find("img").attr("src");
    $(this).css("background-image","url("+src+")");
  });
}

jQuery(function() {
  if($(".js-replace-img")) {
    replaceSrc();
  }
});

// Hide/Show search
jQuery('#search-button').click(function(e) {
  e.preventDefault();
  jQuery('.modal-search-form').toggleClass('show');
});
jQuery('#close-search-modal').click(function(e) {
  e.preventDefault();
  jQuery('.modal-search-form').toggleClass('show');
});

// HEADER

if($("body").hasClass("home")) {

  $(window).scroll(function() {
      var scroll = $(window).scrollTop();

      if (scroll >= 1) {

          $("body").removeClass("nav-down white-header");
      } else {

        $("body").addClass("nav-down white-header");
      }
  });
}

// MENU CART FIXED
function sticky_relocate() {

    var window_top = jQuery(window).scrollTop();
    var anchor;
    if ($('.js-sticky').length && $('.js-sticky-anchor').length){
      anchor = $(".js-sticky-anchor").offset().top - $(".main-header").height();
    }


    if (window_top >= anchor) {
      $('.js-sticky').parent('main').addClass("nav-fixed");
    } else {
      $('.js-sticky').parent('main').removeClass("nav-fixed");
    }
}

// ABOUT BRAND - img src to bg css

function replaceSrc() {

  $('.js-replace-img').each(function() {

    var src = $(this).find("img").attr("src");
    $(this).css("background-image","url("+src+")");
  });
}

$(function() {
  sticky_relocate();
  jQuery(window).scroll(sticky_relocate);

  if($(".js-replace-img")) {
    replaceSrc();
  }
});
